import React from 'react';

const TenHoursDetails = () => {
  return (
    <section className="ser-details section-padding overflow-hidden">
    <div className="container">
      <div className="content">
        <div className="row gx-5">
          <div className="col-lg-8">
            <div className="main-info">
              <div className="main-img img-cover">
                <img src="/assets/img/services/market.webp" alt="" />
              </div>
              <h3 className="text-capitalize mb-20"> Don't Hesitate! Apply for 10 Hours prompt Loan </h3>
              <p className="mb-10">
              We provide fast access to funds for urgent financial needs.
              </p>
              
              <p className="mb-10">
              Are you looking for fast and easy IPPIS loans in Nigeria?
              Put your financial worries to rest with our fast and easy IPPIS loans. Are you wondering if you are eligible? We will take care of that too.
              </p>
              
              
              <h4 className="mt-5 mb-4"> Requirements </h4>
              <p className="mt-10"> <i className="fas fa-check-circle color-blue5 me-2"></i> <strong className="color-000"> 6 Months Bank Statement: </strong> We will need the last six months account statement of your business. 

</p>
              <p className="mt-10"> <i className="fas fa-check-circle color-blue5 me-2"></i> <strong className="color-000"> Collateral Documents: </strong> Original collateral documents are also needed. </p>
              <p className="mt-10"> <i className="fas fa-check-circle color-blue5 me-2"></i> <strong className="color-000"> Valid Identification: </strong> We will need your passport and any valid means of identification for KYC purposes.</p>

              

              
            </div>

            <a href="https://forms.zohopublic.com/MutuaTrustMFB/form/PUBLICSECTORLOANAPPLICATIONFORM2/formperma/qt6twdH1v0w5M8zBnWX8iSwNOgvo0oILNVNsEA2gQEY" className="btn btn-icon-circle rounded-pill bg-blue7 fw-bold text-white me-4" target="_blank">
                    <small> Get Started Now <i className="fas fa-long-arrow-alt-right"></i> </small>
                  </a>
          </div>
          <div className="col-lg-4">
            <div className="side-links mt-5 mt-lg-0">
              <div className="links-card mb-40">
                <h5> Service Category </h5>
                <ul>
                  <li>
                    <a href="#"> <i className="far fa-angle-right icon"></i> 10 Hours Prompt Loan </a>
                  </li>
                  <li>
                    <a href="#"> <i className="far fa-angle-right icon"></i> Mutual Plus Loan </a>
                  </li>
                  <li>
                    <a href="#"> <i className="far fa-angle-right icon"></i> MT loans </a>
                  </li>
                  <li>
                    <a href="#"> <i className="far fa-angle-right icon"></i> SME Loans </a>
                  </li>
                 
                  
                </ul>
              </div>
              <div className="banner1">
                <div className="title">
                  <h6> Call To Action </h6>
                  <h3> Make An Custom Request </h3>
                </div>
                <a href="#" className="butn bg-white rounded-pill hover-blue5">
                  <span> Get A Quote <i className="far fa-long-arrow-right ms-2"></i> </span>
                </a>
                <img src="/assets/img/mob1.png" alt="" className="mob" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default TenHoursDetails